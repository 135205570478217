.MuiDataGrid-root {
    height: 450;
    width: 800;
    background: #081a24;
    color: #aaa;
    border-color: #31c41d;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
    color: #31c41d;
}

.MuiDataGrid-mainGridContainer {
    border-color: #31c41d;
}

/* .MuiDataGrid-columnSeparator {
    color: #31c41d;
} */
.MuiSvgIcon-root .MuiDataGrid-iconSeparator {
    color: #31c41d;
}

* .MuiDataGrid-row {
    border-bottom: 0px;    
}
 
* .MuiDataGrid-cell {
    text-overflow: initial;
    overflow-x: auto;
    color: #aaa;
    border-bottom: 0px;
}

.MuiDataGrid-root .MuiDataGrid-columnSeparator {
    color: #31c41d;
}

.MuiDataGrid-root .MuiDataGrid-footer {
    color: #31c41d;
}

.MuiTablePagination-toolbar {
    color: #31c41d;
}

* .MuiTabs-indicator {
    background-color: #731dc4;
}
